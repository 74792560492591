<template>
  <a-modal :title="modal.title" :visible="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="modal.form">
      <a-form-item label="设备名称">
        <div v-for="(item, index) in modal.data.cameraIndexName" :key="index">
          {{ item }}
        </div>
      </a-form-item>
      <a-form-item label="拆除原因">
        <a-textarea
          v-decorator="[
            'removeReason',
            {
              rules: [{ required: true, message: '请输入拆除原因' }],
            },
          ]"
          placeholder="请输入拆除原因"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {
          cameraIndexName: [],
        },
      },
    }
  },
  computed: {
    typesMap() {
      return {
        state: [
          { value: 1, label: '启用' },
          { value: 0, label: '停用' },
        ],
      }
    },
  },

  methods: {
    async start(rows) {
      this.$reset()
      this.modal.visible = true
      this.modal.data = {
        cameraIndexName: rows.map((row) => row.cameraIndexName),
        cameraIndexCode: rows.map((row) => row.cameraIndexCode),
      }
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },

    async handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            await new Promise((resolve, reject) => {
              this.$confirm({
                title: '提示',
                content: '确认拆除设备?',
                onOk: () => {
                  resolve()
                },
                onCancel: () => {
                  reject(new Error('E_CANCEL'))
                },
              })
            })
            await this.$axios.post('/aie/web/video/device/remove', {
              ...params,
              remove: 1,
              cameraIndexCode: this.modal.data.cameraIndexCode,
            })
            this.$message.success('操作成功!')
            this.$emit('end', 'ok', {})
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>

<style lang="less" scoped></style>
